import { FC } from 'react'

import clsx from 'clsx'
import { id } from 'date-fns/locale'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperModule } from 'swiper/types'

import { LeftArrowGradientJSX, RightArrowGradientJSX } from '@/shared/assets/common/svg'

import styles from './Slider.module.scss'

type Element = {
  item: any
  index: number
  isInList?: boolean
}

interface ISlider {
  slidesPerShow: number
  direction?: 'horizontal' | 'vertical'
  modules?: SwiperModule[]
  data: Record<string, any>[]
  Element: FC<Element>
  isInList?: boolean
  className?: string
  classNameButtons?: string
  classNameSwiperSlide?: string
  spaceBetween?: number
  slideToClickedSlide?: boolean
  initialSlide?: number | undefined
}

export const Slider: FC<ISlider> = ({
  data,
  slidesPerShow,
  direction,
  modules,
  Element,
  isInList,
  className,
  classNameButtons,
  classNameSwiperSlide,
  spaceBetween,
  slideToClickedSlide,
  initialSlide
}) => {
  return (
    <>
      <Swiper
        slidesPerView={slidesPerShow}
        className={clsx(styles.swiper, className)}
        direction={direction}
        modules={modules}
        initialSlide={initialSlide}
        slideToClickedSlide={slideToClickedSlide}
        spaceBetween={spaceBetween || 20}
        normalizeSlideIndex
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
          disabledClass: styles.disabled
        }}>
        {data?.map((item, index) => {
          if (id === undefined || item.id === Number(id)) return null
          return (
            <SwiperSlide
              key={item.id}
              className={classNameSwiperSlide ? classNameSwiperSlide : styles.swiperSlide}>
              <Element index={index} item={item} isInList={isInList} />
            </SwiperSlide>
          )
        })}
        <div className={clsx(styles.buttons, classNameButtons)}>
          <div className={clsx('prev', styles.button)}>
            <LeftArrowGradientJSX />
          </div>
          <div className={clsx('next', styles.button)}>
            <RightArrowGradientJSX />
          </div>
        </div>
      </Swiper>
    </>
  )
}
