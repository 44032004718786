import { lazy } from 'react'

import { Outlet, createBrowserRouter } from 'react-router-dom'

import { ProtectedRoute } from '@/app/providers/protected-router'

import { GameDetail } from '@/pages/games/[id]'
import { NotFound } from '@/pages/not-found'
import { QuizPage } from '@/pages/quiz'

import { QuizVariant1 } from '@/widgets/quiz/variant_1'
import { QuizVariant2 } from '@/widgets/quiz/variant_2'
import { QuizVariant3 } from '@/widgets/quiz/variant_3'

import { SuspenseWrapper } from '@/shared/ui/suspense'

const Experts = lazy(() => import('@/pages/experts'))
const ExpertDetail = lazy(() => import('@/pages/experts/[id]'))

const PrivacyPolicy = lazy(() => import('@/pages/privacy-policy'))
const GambleResponsible = lazy(() => import('@/pages/gamble-responsible'))
const TermsAndConditions = lazy(() => import('@/pages/terms-and-conditions'))

const DetailAnalytics = lazy(() => import('@/pages/experts/[id]/forcast[id]'))

const News = lazy(() => import('@/pages/blog/news'))
const NewDetail = lazy(() => import('@/pages/blog/news/[id]'))

const Blog = lazy(() => import('@/pages/blog'))
const SchoolListCategory = lazy(() => import('@/pages/blog/school'))
const Leaderboard = lazy(() => import('@/pages/leaderboard'))
const BetDictionary = lazy(() => import('@/pages/bet-dictionary'))
const SchoolArticleItem = lazy(() => import('@/pages/blog/school/[id]'))

const SchoolListByCategory = lazy(() => import('@/pages/blog/school/[categoryId]'))
const Bonuses = lazy(() => import('@/pages/bonuses'))
const FAQ = lazy(() => import('@/pages/faq'))
const Home = lazy(() => import('@/pages/home'))
const Shop = lazy(() => import('@/pages/shop'))
const Logout = lazy(() => import('@/pages/profile/logout'))
const ProfilePage = lazy(() => import('@/pages/profile'))
const Sport = lazy(() => import('@/pages/sports'))
const Game = lazy(() => import('@/pages/sports/game/Game'))
const MatchCenterPage = lazy(() => import('@/pages/sports/match-center'))
const Tournament = lazy(() => import('@/pages/sports/tournament'))
const BetsBasket = lazy(() => import('@/widgets/sports/bets-basket/BetsBasket'))
const Games = lazy(() => import('@/pages/games'))

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <SuspenseWrapper>
        <NotFound />
      </SuspenseWrapper>
    )
  },
  {
    // Главная страница (Разводяшка)
    path: '/',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute isMain>
          <Home />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },
  {
    // Бет словарь
    path: 'bet-dictionary',
    element: (
      <SuspenseWrapper>
        <BetDictionary />
      </SuspenseWrapper>
    )
  },
  {
    // FAQ словарь
    path: 'faq',
    element: (
      <SuspenseWrapper>
        <FAQ />
      </SuspenseWrapper>
    )
  },
  {
    path: 'privacy-policy',
    element: (
      <SuspenseWrapper>
        <PrivacyPolicy />
      </SuspenseWrapper>
    )
  },
  {
    path: 'gamble-responsible',
    element: (
      <SuspenseWrapper>
        <GambleResponsible />
      </SuspenseWrapper>
    )
  },
  {
    path: 'terms-and-conditions',
    element: (
      <SuspenseWrapper>
        <TermsAndConditions />
      </SuspenseWrapper>
    )
  },
  {
    path: 'sports',
    element: (
      <SuspenseWrapper>
        <Outlet />
        <BetsBasket />
      </SuspenseWrapper>
    ),
    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <Sport />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Матч центр
        path: 'match-centre',
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <MatchCenterPage />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        path: 'tournament/:id_tournament',
        children: [
          {
            // Турнир
            index: true,
            element: (
              <SuspenseWrapper>
                <ProtectedRoute>
                  <Tournament />
                </ProtectedRoute>
              </SuspenseWrapper>
            )
          },
          {
            path: 'item/:idGame',
            // Игра
            element: (
              <SuspenseWrapper>
                <ProtectedRoute>
                  <Game />
                </ProtectedRoute>
              </SuspenseWrapper>
            )
          }
        ]
      }
    ]
  },
  {
    // Слоты
    path: 'games',
    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <Games />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Деталка игры
        path: 'item/:game_id',
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <GameDetail />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      }
    ]
  },
  {
    // Эксперты
    path: 'experts',

    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <Experts />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Деталка эксперта
        path: 'item/:id',
        element: (
          <SuspenseWrapper>
            <Outlet />
            <BetsBasket />
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <ProtectedRoute>
                  <ExpertDetail />
                </ProtectedRoute>
              </SuspenseWrapper>
            )
          },
          {
            // Форкаст эксперта
            path: 'forcast/:forcastId',
            element: (
              <SuspenseWrapper>
                <ProtectedRoute>
                  <DetailAnalytics />
                </ProtectedRoute>
              </SuspenseWrapper>
            )
          }
        ]
      }
    ]
  },
  {
    // Новостная лента
    path: 'blog',

    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <News />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Подробная новость
        path: 'item/:id',
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <NewDetail />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      }
    ]
  },

  {
    // Новостная лента
    path: 'school',
    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <SchoolListCategory />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Подробная новость
        path: 'slots/:category',
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <SchoolListByCategory />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Урок
        path: 'slots/:category/item/:id',
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <SchoolArticleItem />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      }
    ]
  },

  {
    // Магазин
    path: 'shop',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute>
          <Shop />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },
  {
    // Рейтинг пользователей (Лидерборд)
    path: 'leaderboard',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute>
          <Leaderboard />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },

  {
    path: 'quiz',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute hiddenNoAuth>
          <QuizPage />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },

  {
    path: 'quiz-care',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute hiddenNoAuth>
          <QuizVariant1 />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },
  {
    path: 'quiz-farm',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute hiddenNoAuth>
          <QuizVariant2 />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },
  {
    path: 'quiz-school',
    element: (
      <SuspenseWrapper>
        <ProtectedRoute hiddenNoAuth>
          <QuizVariant3 />
        </ProtectedRoute>
      </SuspenseWrapper>
    )
  },
  {
    path: 'profile',
    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          </SuspenseWrapper>
        )
      },
      {
        // Карточки системы рейтингов
        path: 'bonuses',
        element: (
          <SuspenseWrapper>
            <Bonuses />
          </SuspenseWrapper>
        )
      },
      {
        path: 'logout',
        element: (
          <SuspenseWrapper>
            <Logout />
          </SuspenseWrapper>
        )
      }
    ]
  }
])
